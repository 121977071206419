import React from "react";
import { Box, Flex, Image, Text, Link } from "@chakra-ui/react";
import backgroundImage from "../../images/footer.png";
import FooterLogo from "../../images/clearlogo.png";
import { FaSquareXTwitter, FaLinkedin } from "react-icons/fa6";
import { ChevronRightIcon } from "@chakra-ui/icons";

const Footer = () => {
  return (
    <Box
      bgImage={`url(${backgroundImage})`}
      bgSize="cover"
      bgPosition="top"
      bgRepeat="no-repeat"
      color="white"
      paddingY={120}
      paddingBottom={10}
      paddingX={{ base: 5, md: 20 }}
      style={{ fontFamily: "Avenir, sans-serif" }}
    >
      <Box
        width={{ md: "500px" }}
        ml={{ md: "auto", "2xl": "auto" }}
        mr={{ md: "unset", "2xl": "unset" }}
        // direction={{ base: "column" }}
        // justify="space-between"
        // align={{ base: "center", "2xl": "end" }}
        mb={8}
        // pr={{ base: 0, md: 20 }}
        // pl={{ base: 0, md: 40 }}
        // pb={{ base: 0, md: 10 }}
      >
        <Box
          // flexBasis={{ base: "100%", md: "50%" }}
          textAlign={{ base: "center", md: "right", "2xl": "right" }}
          mb={{ base: 4, md: 0 }}
          // ml={{ base: 0, md: 20 }}
        >
          <Text fontSize={{ base: "xl", md: "xl" }} fontWeight="bold">
            Subscribe to our newsletter today
          </Text>
          <Text fontSize={{ base: "md", md: "l" }} ml={4} mt={2}>
            Stay up to date with the latest happenings at Innovate Africa Fund
          </Text>
        </Box>
        <Box
          display="flex"
          // alignItems={{ base: "center", "2xl": "end" }}
          alignItems="center"
          justifyContent={{ base: "center", md: "end", "2xl": "end" }}
        >
          <Link
            href="https://docs.google.com/forms/d/e/1FAIpQLScqpVcsuOOIVL9R8lV-kqoatdh64ZMAkOj35lwE-EQfBM_HFA/viewform?usp=sf_link"
            textDecoration="none"
            _hover={{ textDecoration: "none" }}
            bg="white"
            color="rgba(128, 0, 32, 100)"
            borderRadius="8"
            display="inline-flex"
            gap={3}
            px={4}
            py={3}
            mt={4}
            fontWeight="600"
            alignItems="center"
          >
            SUBSCRIBE
            <ChevronRightIcon />
          </Link>
        </Box>
      </Box>

      <Flex
        direction={{ base: "column", md: "row" }}
        justify="flex-end"
        align="center"
        // pr={{ base: 0, md: 20 }}
        gap={{ base: 0, md: 70 }}
      >
        <Flex
          direction="column"
          align="center"
          mb={{ base: 8, md: 0 }}
          mr={{ base: 0, md: 8 }}
        >
          <Image src={FooterLogo} alt="Logo" mb={7} />
          <Flex>
            <Link
              href="https://x.com/innovatefund?s=21"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaSquareXTwitter size={24} />
            </Link>
            {/* <Link href="#" target="_blank" rel="noopener noreferrer" ml={2}>
              <FaInstagram size={24} />
            </Link> */}
            <Link
              href="https://www.linkedin.com/company/innovateafricafund/"
              target="_blank"
              rel="noopener noreferrer"
              ml={2}
            >
              <FaLinkedin size={24} />
            </Link>
            {/* <Link href="#" target="_blank" rel="noopener noreferrer" ml={2}>
              <FaFacebook size={24} />
            </Link> */}
          </Flex>
        </Flex>

        <Flex direction={{ base: "column", md: "row" }} align="center">
          <Link href="/contact" fontSize={{ base: "lg", md: "xl" }} mr={4}>
            Contact Us
          </Link>
          <Link href="/about" fontSize={{ base: "lg", md: "xl" }} mr={4}>
            About Us
          </Link>
          <Link href="/blog" fontSize={{ base: "lg", md: "xl" }} mr={4}>
            Blog
          </Link>
          <Link href="/events" fontSize={{ base: "lg", md: "xl" }} mr={4}>
            Events
          </Link>
          <Link href="/terms" fontSize={{ base: "lg", md: "xl" }} mr={4}>
            Terms of Use
          </Link>
          <Link href="/privacy-policy" fontSize={{ base: "lg", md: "xl" }}>
            Privacy Policy
          </Link>
        </Flex>
      </Flex>
    </Box>
  );
};

export default Footer;
